import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Header } from "@components/navbar";
import { Footer } from "@components/footer";
import { SimpleLoader as Loader } from "@components/loaders/simple_loader";
import { EntryProvider } from "@components/entry_provider";
import { WhataApp } from "@components/whatsapp";
import { SuspenseLazyLoader } from "@components/lazy_loaders";
import { stringToId, useAosAttribute } from "./hooks";

// Note : don't change the order
import "@common/font/font.css";
import "@common/text.css";
import "@common/title.css";
import "@common/global.override.css";
import "@common/global.css";
import "@common/swiper-slider/swiper.style.override.css";
import { useStore } from "@store/saree.store";
// import ServiceDown from "@components/service_down";

// --------- Lazy-loaded components ----------
const NotFound = SuspenseLazyLoader(
  React.lazy(() => import("@components/page_not_found"))
);
const Home = SuspenseLazyLoader(React.lazy(() => import("@pages/home")));
const About = SuspenseLazyLoader(React.lazy(() => import("@pages/about")));
const Faqs = SuspenseLazyLoader(React.lazy(() => import("@pages/faqs")));
const Contact = SuspenseLazyLoader(React.lazy(() => import("@pages/contact")));
const AllCollections = SuspenseLazyLoader(
  React.lazy(() => import("@pages/all-collections"))
);
const Product = SuspenseLazyLoader(React.lazy(() => import("@pages/product")));
const Cart = SuspenseLazyLoader(React.lazy(() => import("@pages/cart/render")));
const ReturnExchangePolicy = SuspenseLazyLoader(
  React.lazy(() => import("@pages/policy/return.exchange"))
);
const ShippingPolicy = SuspenseLazyLoader(
  React.lazy(() => import("@pages/policy/shipping"))
);
const TermsConditions = SuspenseLazyLoader(
  React.lazy(() => import("@pages/policy/terms.conditions"))
);
const Privacy = SuspenseLazyLoader(
  React.lazy(() => import("@pages/policy/privacy"))
);

// -------------------------------------------

function App() {
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(false);

  useAosAttribute(isLoader);

  useEffect(() => {
    setIsLoader(true);

    setTimeout(() => {
      setIsLoader(false);
    }, 600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <EntryProvider>
        {isLoader && <Loader />}
        {!isLoader && (
          <React.Fragment>
            <Header />
            <WhataApp />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/story" element={<About />} />
              <Route path="/faqs" element={<Faqs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/collections" element={<AllCollections />} />
              <Route path="/collections/product/:id" element={<Product />} />
              <Route path="/cart" element={<Cart />} />
              <Route
                path="/return-exchange-policy"
                element={<ReturnExchangePolicy />}
              />
              <Route path="/shipping-policy" element={<ShippingPolicy />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="*" element={<NotFound />} />
            </Routes>

            <Footer />
          </React.Fragment>
        )}
      </EntryProvider>

      {/* <ServiceDown /> */}
    </>
  );
}

export default App;
