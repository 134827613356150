import { useEffect } from "react";
import styles from "./loader.style.module.css";
import "./style.css";

export const SimpleLoader = ({
  className,
  allowText = true,
  color = "#000000",
}) => {
  useEffect(() => {
    document.body.classList.add("no-scroll");

    // Cleanup on unmount
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  return (
    <div
      style={{ "--loader-circle-color": color }}
      className={`${styles.loaderContainer} ${className}`}
    >
      <div className={styles.circle}></div>
      {allowText && <p>Loading</p>}
    </div>
  );
};
