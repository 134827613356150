import { stringToId } from "@hooks/index";

import shopByCollection_01 from "@images/home/shop-by-collections/shop-by-collection-01.jpg";
import shopByCollection_02 from "@images/home/shop-by-collections/shop-by-collection-02.jpg";
import shopByCollection_03 from "@images/home/shop-by-collections/shop-by-collection-03.jpg";

import Product50001002_1 from "@images/products/50001002/50001002.1.jpg";
import Product50001002_2 from "@images/products/50001002/50001002.2.jpg";
import Product50001002_3 from "@images/products/50001002/50001002.3.jpg";

import Product50001003_1 from "@images/products/50001003/50001003.1.jpg";
import Product50001003_2 from "@images/products/50001003/50001003.2.jpg";
import Product50001003_3 from "@images/products/50001003/50001003.3.jpg";

import Product20007001_1 from "@images/products/20007001/20007001.1.jpg";
import Product20007001_2 from "@images/products/20007001/20007001.2.jpg";
import Product20007001_3 from "@images/products/20007001/20007001.3.jpg";

import Product20007002_1 from "@images/products/20007002/20007002.1.jpg";
import Product20007002_2 from "@images/products/20007002/20007002.2.jpg";
import Product20007002_3 from "@images/products/20007002/20007002.3.jpg";

import Product50001001_1 from "@images/products/50001001/50001001.1.jpg";
import Product50001001_2 from "@images/products/50001001/50001001.2.jpg";
import Product50001001_3 from "@images/products/50001001/50001001.3.jpg";

import Product40005002_1 from "@images/products/40005002/40005002.1.jpg";
import Product40005002_2 from "@images/products/40005002/40005002.2.jpg";
import Product40005002_3 from "@images/products/40005002/40005002.3.jpg";

import Product40005003_1 from "@images/products/40005003/40005003.1.jpg";
import Product40005003_2 from "@images/products/40005003/40005003.2.jpg";

import Product40006002_1 from "@images/products/40006002/40006002.1.jpg";
import Product40006002_2 from "@images/products/40006002/40006002.2.jpg";

import Product30019001_1 from "@images/products/30019001/30019001.1.jpg";
import Product30019001_2 from "@images/products/30019001/30019001.2.jpg";
import Product30019001_3 from "@images/products/30019001/30019001.3.jpg";

import Product30019002_1 from "@images/products/30019002/30019002.1.jpg";
import Product30019002_2 from "@images/products/30019002/30019002.2.jpg";
import Product30019002_3 from "@images/products/30019002/30019002.3.jpg";

import Product30010002_1 from "@images/products/30010002/30010002.1.jpg";
import Product30010002_2 from "@images/products/30010002/30010002.2.jpg";
import Product30010002_3 from "@images/products/30010002/30010002.3.jpg";

import Product60002001_1 from "@images/products/60002001/60002001.1.jpg";
import Product60002001_2 from "@images/products/60002001/60002001.2.jpg";
import Product60002001_3 from "@images/products/60002001/60002001.3.jpg";

import Product30011002_1 from "@images/products/30011002/30011002.1.jpg";
import Product30011002_2 from "@images/products/30011002/30011002.2.jpg";

export const COLLECTION_NAMES = {
  FESTIVE_WEAR: {
    id: "festive-wear",
    name: "Festive Wear",
  },
  CASUAL_WEAR: {
    id: "casual-wear",
    name: "Casual Wear",
  },
  DESIGNER_WEAR: {
    id: "designer-wear",
    name: "Designer Wear",
  },
};

export const ALL_PRODUCT_COLLECTIONS = [
  {
    id: COLLECTION_NAMES.FESTIVE_WEAR.id,
    name: COLLECTION_NAMES.FESTIVE_WEAR.name,
    image: shopByCollection_01,
  },
  {
    id: COLLECTION_NAMES.CASUAL_WEAR.id,
    name: COLLECTION_NAMES.CASUAL_WEAR.name,
    image: shopByCollection_02,
  },
  {
    id: COLLECTION_NAMES.DESIGNER_WEAR.id,
    name: COLLECTION_NAMES.DESIGNER_WEAR.name,
    image: shopByCollection_03,
  },
];
