import { COLLECTION_NAMES } from "@pages/saree.collections";

export const MENU_LIST = [
  { name: "Home", path: "/" },
  { name: "Story", path: "/story" },
  {
    name: "Collections",
    dropdown: [
      { name: "Shop All", path: "/collections" },
      {
        name: COLLECTION_NAMES.FESTIVE_WEAR.name,
        id: COLLECTION_NAMES.FESTIVE_WEAR.id,
        path: "/collections",
      },
      {
        name: COLLECTION_NAMES.CASUAL_WEAR.name,
        id: COLLECTION_NAMES.CASUAL_WEAR.id,
        path: "/collections",
      },
      {
        name: COLLECTION_NAMES.DESIGNER_WEAR.name,
        id: COLLECTION_NAMES.DESIGNER_WEAR.id,
        path: "/collections",
      },
    ],
  },
  { name: "FAQ's", path: "/faqs" },
  { name: "Contact us", path: "/contact" },
];
