import React from "react";
import { ConfigProvider } from "antd";
// import { ParallaxProvider } from "react-scroll-parallax";

// import { SmoothScrollbar } from "@components/scroll_widgets/smooth_scrollbar";

export const EntryProvider = ({ children }) => {
  const customTheme = {
    token: {
      colorPrimary: "#b4903a",
    },
  };

  return (
    <React.Fragment>
      <ConfigProvider theme={customTheme}>
        {/* <ParallaxProvider> */}
        {/* <SmoothScrollbar> */}
        {children}
        {/* </SmoothScrollbar> */}
        {/* </ParallaxProvider> */}
      </ConfigProvider>
    </React.Fragment>
  );
};
