import React from "react";
import { useShallow } from "zustand/react/shallow";
import { useNavigate } from "react-router-dom";
import { FaCartShopping } from "react-icons/fa6";

import { useStore } from "store/saree.store";

import styles from "./cart.module.css";

export const CartMenu = ({ allowText = true, className }) => {
  const { cartData } = useStore(
    useShallow((state) => ({ cartData: state.cartData }))
  );
  const navigate = useNavigate();
  return (
    <div
      className={`${styles.cartContainer} ${className}`}
      onClick={() => navigate("/cart")}
    >
      <div className={`${styles.iconContainer}`}>
        <FaCartShopping className={`${styles.icon}`} />
        <span className={`${styles.count}`}>{cartData.length}</span>
      </div>
      {allowText && <p className={`text-18 weight-400`}>Cart</p>}
    </div>
  );
};
