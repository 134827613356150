import { useEffect, useLayoutEffect } from "react";
import { AOS_ANIMATION } from "@common/aos_animation";

export const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};

export const stringToId = (data) => {
  return data.toLowerCase().replaceAll(" ", "-");
};

export const useAosAttribute = (isLoader) => {
  useLayoutEffect(() => {
    const aosAppliedElements = document.querySelectorAll("#sui-aos");
    aosAppliedElements.forEach((element) => {
      element.setAttribute("data-aos", AOS_ANIMATION.FADE_UP.TYPE);
      element.setAttribute("data-aos-duration", AOS_ANIMATION.FADE_UP.DURATION);
      element.removeAttribute("sui-aos");
    });

    const ffAppliedElements = document.querySelectorAll("#sui-ff");
    ffAppliedElements.forEach((element) => {
      element.classList.add("poppins-regular");
      element.removeAttribute("#sui-ff");
      element.removeAttribute("id");
    });
  }, [isLoader]);
};

export const debounce = (func, delay = 500) => {
  let timeoutId;
  return () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(), delay);
  };
};
