export const SORT = {
  A_Z: "Alphabetically A - Z",
  Z_A: "Alphabetically Z - A",
  PRICE_LOW_HIGH: "Price low to high",
  PRICE_HIGH_LOW: "Price high to low",
};

export const AVAILABILITY = {
  IN_STOCK: "In Stock",
  OUT_OF_STOCK: "Out of Stock",
};

export const fetchSheetData = async () => {
  try {
    const response = await fetch(
      "https://script.google.com/macros/s/AKfycbxU6Gb4LPQpJ_6u5a8yIIxPPfbWv8GCzuh1R3ilAH2AL5o3PlTBemGHY1EEeJcHLqA1/exec"
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    console.log("sheet data fetched ", response);
    return response.json();
  } catch (error) {
    console.error("Error fetching sheet data:", error.message || error);
  }
};