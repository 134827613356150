import React, { Suspense } from "react";

import { SimpleLoader as Loader } from "@components/loaders/simple_loader";

export const SuspenseLazyLoader = (Component) => {
  return (props) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
};
