import { Footer1 } from "./footer_1";

export const Footer = ({ variant }) => {
  switch (variant) {
    case "varian1":
      return <Footer1 />;
    default:
      return <Footer1 />;
  }
};
