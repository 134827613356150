import React from "react";

import { HeaderMaxWidthDropDown } from "./desktop/maxwidth_dropdown";
import { HeaderRightDropDown } from "./mobile/right_dropdown";

import styles from "./navbar.style.module.css";

export const Header = () => {
  return (
    <React.Fragment>
      <div className={styles.deskHeader}>
        <HeaderMaxWidthDropDown />
      </div>
      <div className={styles.mobileHeader}>
        <HeaderRightDropDown />
      </div>
    </React.Fragment>
  );
};
