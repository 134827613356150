import { create } from "zustand";

import { SORT } from "@pages/constant";
import { stringToId } from "@hooks/index";

let getCartData = localStorage.getItem("dhanika-cart");

const initialState = {
  allProducts: [],
  filter: {
    collections: [],
    priceRange: [0, 10000],
    availability: [],
    sort: SORT.A_Z,
    dataCount: 0,
  },
  cartData: [...(JSON.parse(getCartData) || [])],
  sheetData: [],
};

export const useStore = create((setState) => ({
  ...initialState,
  setFilter: (data) => {
    setState((state) => {
      return { filter: { ...state.filter, ...data } };
    });
  },
  setCartData: (data) => {
    setState(() => {
      localStorage.setItem("dhanika-cart", JSON.stringify(data));
      return { cartData: [...data] };
    });
  },
  setAllProducts: (data) => {
    setState((state) => {
      return { allProducts: data };
    });
  },
  setSheetData: (data) => {
    setState((state) => {
      return {
        sheetData: data,
        allProducts: data,
        filter: {
          ...state.filter,
          dataCount: data.length,
        },
      };
    });
  },
  getSheetData: (data) => {
    setState((state) => {
      return {
        isLoader: true,
      };
    });

    let updatedData = data.map((item) => {
      let imagesList = [];
      Object.keys(item).forEach((key) => {
        if (key.includes("Image0")) {
          let getImage = item[key];
          getImage && imagesList.push({ id: key, image: getImage });
        }
      });

      return {
        id: stringToId(
          `${item["SNo"]} ${item["SareeName"]} ${item["SareeCode"]}`
        ),
        name: `${item["SareeName"]}`,
        price: item["Price"],
        discountPrice: item["DiscountPrice"],
        quantity: item["Quantity"],
        code: item["SareeCode"],
        blouseType: item["BlouseType"],
        collection: item["Collection"],
        images: imagesList,
        productDetails: [
          {
            title: "Product details",
            description: item["Description"],
            productInfo: [{ "Blouse Type": item["BlouseType"] }],
          },
        ],
      };
    });

    setState((state) => {
      return {
        isLoader: false,
        sheetData: updatedData,
        allProducts: updatedData,
        filter: {
          ...state.filter,
          dataCount: updatedData.length,
        },
      };
    });
  },
}));
